/* Emissor.css */

.mainDoc4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    min-height: 50vh;
}

.pagination {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    background-color: #1a2f67;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.pagination button:disabled {
    background-color: #aaa;
    cursor: default;
}

.pagination span {
    margin: 0 10px;
}


.aboveButton {
    cursor: pointer;
    padding: 1vh;
    color: #1a2f67;
}

.aboveButton:hover {
    background-color: #aeaeae5f;
    border-radius: 8px;
}

.divTitleDoc1 {
    background-color: rgb(250, 250, 250);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 88%;

}

.divTitleDoc11 {
    width: 100%;
}

.divTitleDoc2 {
    background-color: #f7f7f7;
    text-align: start;
    color: #1a2f67;
    font-size: 1.2rem;
    /* Define uma largura máxima para o contêiner */
    /* Adicione margem superior */
}

.divVoltarGarantia {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    font-weight: 600;
    cursor: pointer;
}

.emissorDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fefeff;
}

.garantiaForm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 3%;
    padding: 3%;
    background-color: #fefeff;
    border-radius: 10px 10px 10px 10px;
}

.file-input {
    background-color: #1a2f67;
    color: white;
}

.custom-file-upload {
    background-color: #1a2f67;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 12px;
    margin-left: 10px;
}



.custom-file-upload:hover {
    background-color: #1a2f67;
}

.buttonSendGarantia {
    background-color: #004f9f;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 10px 12px;
    margin-left: 10px;
}

.divGarantia1 {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-content: space-between;
    justify-content: space-between;
}


.h3Doc6 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
    /* Cor do título */
}



.checkboxDiv1:hover {
    background-color: #f5f5f5;
    /* Cor de destaque ao passar o mouse sobre um item */
}

.checkboxLabel1 {
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 20%;
}

.emissorTable {
    width: 90%;
    margin: 0;
    padding: 0;
}

.emissorTable th {
    border: 0px;
    background-color: #fff;
    color: #1a2f67;
    text-align: center;
}

.tableIcons {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.tableIcons input {
    cursor: pointer;
}

.eyeIcon {
    display: flex;
    background-color: #007bff;

    cursor: pointer;
}

.emissorTable td {
    border: 0px;
    background-color: #fff;
    color: #000;
    text-align: center;
}

.checkboxLabel {
    align-self: center;
    font-size: 18px;
    cursor: pointer;
    color: #333;
    text-align: center;
    width: 20%;
    /* Cor do texto */
}

.checkboxLabel1:hover {
    color: red;
}

.checkboxInput1 {
    margin-right: 10px;
}

.checkboxDiv1 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    transition: background-color 0.3s;
    justify-content: center;
    text-align: center;
    padding: 10px;
    width: 95%;
}

.newButton1 {
    background-color: #1f3267f3;
    padding: 10px 15px;
    border-radius: 8px;
    color: white;
    text-align: center;
    cursor: pointer;
    width: 40%;
    margin-bottom: 10px;
    align-self: flex-end;
    box-shadow: #0000005e 0px 1px 3px;
}

.checkboxInfo {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.button11 {
    padding: 10px 10px;
    background-color: #1a2f67;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
    margin-right: 20px;
    /* Adiciona uma transição suave na mudança de cor de fundo */
    /* Defina a largura desejada */
}

.button11:hover {
    background-color: #1a2f67;
}


.successMessage {
    color: green;
    font-size: 14px;
    margin-top: 10px;
}

.errorMessage {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.divDelete {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.detailsDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
}

.detailsDiv1 {
    padding: 3% 1.5%;
    border-radius: 8px;
    background-color: #fefeff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: #00000033 0px 2px 5px;
}

.detailsDiv11 {
    border-radius: 8px;
    background-color: #fefeff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.detailsDiv2 {
    background-color: #fefeff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2%;
    border-top: 5px solid #1a2f67;
    border-radius: 10px;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    justify-content: center;
}

.deleteButton {
    background-color: white;
    color: red;
}

.buttonGarantia {
    display: flex;
    background-color: #fff;
    border: 2px solid #1a2f67;
    padding: 0;
    border-radius: 8px;
    color: #1a2f67;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    height: 3vh;
    padding: 1vh;
    align-self: center;
}

.updateButton {
    background-color: white;
    color: #93C01F;
}

.updateButton:hover {
    background-color: #54545417;
    color: #93C01F;
}

.confirmationButton {
    background-color: #1a2f67;
    color: rgb(255, 255, 255);
    font-size: 14px;
    margin-top: 10px;
    margin-right: 22px;
    width: 100%;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
    align-self: center;
    justify-self: center;
    text-align: start;

}

.confirmationMessage {
    color: rgb(0, 0, 0);
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    background-color: #fefeff;
}

.confirmationMessage11 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: #1a2f67;
    font-size: 22px;
    margin-top: 10px;
    width: 100%;
    text-align: start;
    border-bottom: 3px solid #1a2f67;
    padding-bottom: 10px;
}

.newDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.newDiv1 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-radius: 10px;
}

.deleteButton:hover {
    background-color: rgb(255, 239, 239);
    color: red;
}

.inputTextarea {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    height: 6vh;
    width: 90%;
    border: #cfcfcf 2px solid;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 2vh;
    resize: vertical;
}
.inputTextarea-2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    height: 14vh;
    width: 90%;
    border: #cfcfcf 2px solid;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 2vh;
    resize: vertical;
}

.selectDoc1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-left: 3%;
    padding-right: 3%;
    height: 5.5vh;
    width: 95%;
    border: #cfcfcf 2px solid;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 2vh;
}

.newInput {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 10px;
    height: 3vh;
    width: 90%;
    border: #cfcfcf 2px solid;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 2vh;
    resize: vertical;
}

.fileLabel {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    padding: 10px 20px;
    margin: 10px;
    text-align: center;
    min-width: 35%;
    height: 5vh;
    width: 40%;
}