.bodyUpdt {
    padding-top: 1rem;
    font-family: Arial, sans-serif;
    background-color: #00336a;
    background-image: url('../images/background-web.png');
    background-size: cover;
   /* height: 100%;*/
}


.returnArrow {
    display: flex;
    align-items: center;
    color: #1B2E67;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 4vh;
    height: 8vh;
}

.labelForgot {
    color: #000000;
    font-size: 1.2rem;
}

.otherTitle {
    color: #000;
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
}

.divCad {
    display: flex;
    height: 100vh;
}

.inputWrapper {
    padding: 2rem;
    width: 37%;
    position: absolute;
    right: 4rem;
}

.labelPass {
    color: rgb(37, 37, 37);
    margin-bottom: 5px;
}

.inputPass {
    font-size: 14px;
    border: 2px solid rgba(116, 116, 116, 0.26);
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 3px rgba(84, 84, 84, 0.305);
}

button {
    padding: 10px 20px;
    background-color: #004f9f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
    margin-left: 10px;
    transform: 500ms;
    transition: 0.5s;
}

button:hover {
    background-color: #0067ce;
    color: white;
}

.logo {
    align-self: center;
    width: 140px;
    height: 100px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.rowButtons {
    display: flex;
    flex-direction: row;
}