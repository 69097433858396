.emittedBody {
    display: flex;
    margin: 10px;
}

.emittedMain {
    display: flex;
    flex-direction: column;
    margin-left: 25%;
    width: 100%;
}

.divEmitted {
    border-top: 5px #1a2f67 solid;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    height: 90vh;
    width: 95%;
}

.tableEmitted {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    
}


.emittedShowDetails {
    cursor: pointer;
    color: #1a2f67;
}

.tableEmitted th {
    background-color: #fff;
    color: #1a2f67;
    padding: 10px;
    text-align: center;
    border: 0px;
    font-size: 1.1rem;
    font-weight: 500;
}


.tableEmitted td {
    border: 0px;
    text-align: center;
}

.tableEmitted tr:nth-child(even) {
    background-color: #f1f2f6;
}

.tableEmitted tr:hover {
    background-color: #eaeaea;
}

/* Modal Backdrop Styles */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modal-container {
    position: relative;
    margin: 0 auto;
    max-width: 90%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    overflow-y: auto;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    top: 10%;
}



.modal-container .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #555;
}

.modal-container .close-button:hover {
    color: #ff4444;
}



/* Optional: Add responsive styles for smaller screens */
@media (max-width: 768px) {
    .tableEmitted {
        display: block;
    }

    .tableEmitted thead {
        display: none;
    }

    .tableEmitted tbody {
        display: block;
    }

    .tableEmitted tr {
        margin-bottom: 10px;
        display: block;
        border: 1px solid #ccc;
    }

    .tableEmitted td {
        border: none;
        position: relative;
        padding-left: 50%;
        text-align: left;
    }

    .tableEmitted td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 45%;
        padding-left: 10px;
        font-weight: bold;
    }
}

.button111 {
    width: 16vh;
    margin: 2px;
    font-size: 1.9vh;
    height: 4vh;
}

.confirmation-modal {
    position: fixed;
    top: 50%;
    /* Centraliza verticalmente */
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    /* Certifique-se de que este valor seja maior que o z-index do modal de notas */
    text-align: center;
    /* Centraliza o conteúdo horizontalmente */
}

.confirmation-modal p {
    margin: 10px 0;
    color: #555;
}

.confirmation-modal button {
    width: 100px;
    /* Largura dos botões de confirmação */
    margin: 5px;
    font-size: 16px;
    height: 40px;
}