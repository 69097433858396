.detailsInvestidor {
    width: 98%;

}

.divTitleInvestidor {
    background-color: #fff;
    border-radius: 8px;
    border-top: 5px solid #1a2f67;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 2%;
    margin-top: 6%;
    overflow: auto;
    padding: 20px;
    width: 90%;
}
.divTitleInvestidor2 {
    background-color: #fff;
    border-radius: 8px;
    border-top: 5px solid #1a2f67;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  /*  margin-left: 2%;*/
    margin-top: 6%;
    overflow: auto;
    padding: 20px;
    width: 90%;
   /* background-color: #db0f0f;*/
}


.investidorMessage1 {
    color: #1a2f67;
    font-size: 1.4rem;
}

.investidorTable {
    font-size: 1.2rem;
}

.investidorTable2 {
    display: flex;
    width: 100%;
    justify-content: center;
}

.investidorTable tr th,
.investidorTable tr td {
    border: 0px;
    background-color: white;
    text-align: center;
}

.investidorTable tr th {
    color: #1a2f67;
}

.investidorTable td {
    color: #2e2e2e;
    font-size: 1.1rem;
}

.divButtonsAction {
    display: flex;
}

.tableIcons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.invesDelete,
.invesUpdt {
    cursor: pointer;
    color: #db0f0f;
    margin-right: 10px;
}


.buttonTd {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.investidorContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 5px;
    padding: 12px;
    justify-content: center;
}

.investidorField {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-bottom: 10px;
    margin-top: 0px;
    padding: 5px;
  
}
.investidorField2 {
    width: 100%;
    margin-bottom: 10px;
}

.invesP {
    padding: 5px;
    font-weight: bold;
    border-radius: 8px;
}

.invesP1 {
    padding: 1rem;
    border: 2px solid #ccc;
    border-radius: 8px;
    width: 90%;
}
.invesP3 {
    padding: 1rem;
    border: 2px solid #ccc;
    border-radius: 8px;
   
}
.invesP2 {
    padding: 1rem;
    border: 2px solid #ccc;
    border-radius: 8px;
    width: 95%;
}

/* Estilos para o fundo escuro */
.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Estilos para o modal */
/* Estilos para o modal com sobreposição */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Estilos para centralizar o modal na tela */
.modal-content {
    position: relative;
    width: 60%;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
}

.modalClose {
    background-color: #fff;
    /* Fundo branco */
    color: #FF0000;
    /* Cor vermelha para o botão de fechar */
    font-size: 20px;
    cursor: pointer;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 20px;
}
.opacoButton {
    background-color: #ccc; /* Cor de fundo opaca */
    color: #666 !important; /* Cor do texto opaca */
    text-decoration: none;
    cursor: not-allowed; /* Cursor indicando que o botão não está disponível */
    pointer-events: none; /* Evita que o botão seja clicado */
    text-align: center;
    justify-content: center;
    
  }
  
  @media screen and (min--moz-device-pixel-ratio: 0.9),
  screen and (-o-min-device-pixel-ratio: 0.9/1),
  screen and (-webkit-min-device-pixel-ratio: 0.9),
  screen and (max-resolution: 119dpi) {
   /* .invesP3 {
        width: 80%;
    }*/
  }