.backColor {
    background-color: #00336a;
    padding-bottom: 1%;
}

.header {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img {
    width: 130px;
    height: 100px;
}

.divCadastro {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.divInputs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    margin-bottom: 10px;
}

.label {
    color: rgb(26, 26, 26);
    margin-bottom: 5px;
    /* Adicione um espaço entre o label e o input */
}

.inputR {
    font-size: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px 5px 5px 5px;
    border-color: grey;
    margin-right: 4%;
    height: 20px;
    padding: 2%;
}

.inputR:focus {
    border-color: #00345c;
    outline: none;
    box-shadow: 0 0 7px rgba(89, 184, 255, 0.5);
}

.box {
    margin: 3%;
    background-color: white;
    padding: 5%;
    border-radius: 8px;
}

.botao {
    /* Remova as estilizações padrão */
    border: none;
    background: none;
    outline: none;
    box-shadow: none;

    /* Defina os estilos personalizados */
    font-size: 14px;
    font-weight: bold;
    color: #9ad413;
    cursor: pointer;
}

.button {
    /* Remova as estilizações padrão */
    border: none;
    background: none;
    outline: none;
    box-shadow: none;

    /* Defina os estilos personalizados */
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #1a2f67;
    border-radius: 8px;
    cursor: pointer;
}