.garantiasDiv {
    width: 70%;
    margin-left: 23%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-items: flex-end;
    /*border: 2px solid #1a2f67;*/
    border-radius: 10px;
    padding: 20px;
}
.boxGarantia {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 2px solid #1a2f67;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}
.divInputsDocAvaG {
    display: flex;
    flex-direction: column;
    width: 45%;
   /* background-color: #4ac0b5;*/
}


.garantiasBody {
    background-color: #fff;
    padding: 10px;

}
@media (min-width: 1800px) {
    .divInputsDocAvaG {
        width: 55%;
       /* background-color: #4ac0b5;*/
    }
   
}