.docBody {
    background-color: #f7f7f7;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 80vh;
    align-items: center;
}

.invesBody {
    background-color: #f7f7f7;
    padding: 10px;
    
}

.investidorDoc2 {
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    border-top-width: 0;
    display: flex;
    flex-direction: column;
    margin-left: 24%;
    padding-bottom: 14%;
    width: 80%;
}
.investidorDoc3 {
    align-items: center;
    border-radius: 10px 10px 10px 10px;
    border-top-width: 0;
    display: flex;
    flex-direction: column;
    margin-left: 24%;
    padding-bottom: 14%;
  /*  background-color: #ff5c5c;*/
   /* margin-top: 5%;*/
}

.divToggle {
    display: flex;
    width: 100%;
    justify-content: start;
    margin-left: 70px;
}

.representante-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;


}

.deleteButton {
    background-color: #ff5c5c;
    color: white;
    border: none;
    padding: 5px 8px;
    cursor: pointer;
}

.per1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1a2f67;
    font-size: 1.2rem;
    margin-top: 10px;
    width: 100%;
    text-align: start;
    background-color: #fefeff;
    padding-bottom: 1%;
    border-bottom: 2px #11214c solid;
}

.ulDoc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.formDoc {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    border-left: 5px #1a2f67 solid;
    background-color: #fefeff;
    margin-left: 1% !important;
    width: 83%;
}

.divTitleDoc {
    display: flex;
    justify-content: left;
}

.h1Doc1 {
    color: #1a2f67;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1vh;
    margin-top: 3vh;
}

.h1Doc2 {
    color: #1a2f67;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 5vh;
    margin-top: 3vh;
}
.h1Doc3 {
    color: #1a2f67;
    font-size: 1.7rem;
    font-weight: 700;
    text-align: left;
}
.h1Doc4 {
    color: #1a2f67;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: left;
}

.divInputsDoc {
    display: flex;
    flex-direction: column;
    width: 30%;
   /* border: 2px solid salmon;*/
   
}
.divRepresentantes {
    display: flex;
    align-items: center;
    align-self: stretch;
    width: 100%;
    margin-top: 1rem;
    /*margin-left: 0.7rem;*/
    flex-direction: row;
}
.divRepresentantes2 {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}
.botaoRemove{
    height: 45px;
    margin-top: 10px;
}

.rowRepre{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.divRep2 {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.inputsRep {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500;
    padding-left: 2vh;
    padding-right: 2vh;
    height: 45px;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 90%;
    margin-bottom: 2vh;
   /* background-color: #1a2f67;*/
}
.inputsRep2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500;
    padding-left: 2vh;
    padding-right: 2vh;
    height: 45px;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  /*  width: 80%;*/
    margin-bottom: 2vh;
   /* background-color: yellow;*/
    margin-left: 25px;
}
.inputsRep3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500;
    padding-left: 2vh;
    padding-right: 2vh;
    height: 45px;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 86%;
    margin-bottom: 2vh;
   /* background-color: tomato;*/
    margin-left: 50px;
}

.buttonRep {
    display: flex;
    width: 3.5rem;
    padding: 0.5rem;
    justify-content: center;
    align-items: baseline;
    gap: 0.5rem;
    background-color: #11214c;
}

.buttonRep1 {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 0.5rem;
    color: #1a2f67;
    font-weight: 600;
    font-size: 1.2rem;
    width: 100%;
    padding: 0.5rem;
    cursor: pointer;
}

/*.labelInputsDoc {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: left;
    margin-bottom: 1vh;
    width: 97%;
}*/

/*.inputsDoc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    padding-left: 2vh;
    padding-right: 2vh;
    height: 5vh;
    border: #cfcfcf 2px solid;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 85%;
    margin-bottom: 2vh;
   
}*/

/*.selectDoc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-left: 3%;
    padding-right: 3%;
    height: 5.5vh;
    width: 97%;
    border-radius: 8px;
    display: flex;
}*/

.expandedDoc {
  /*  max-width: 1000px;*/
    /* Defina o valor máximo apropriado para a altura expandida */
    transition: max-width 0.7s ease-in-out;
    opacity: 1;
}

/* Classe para ocultar os elementos */
.collapsed {
    max-width: 200px;
    overflow: hidden;
    opacity: 0;
    transition: max-width 0.7s ease-in-out, opacity 0.5s ease-in-out;
}

.divChange {
    display: flex;
    flex-direction: row;
    margin-left: 24%;
    justify-content: center;
    width: 100%;
}

.buttonChangeIn {
    background-color: #1a2f67;
    text-align: center;
}

.newButton {
    background-color: #1f3267f3;
    padding: 10px;
    border-radius: 8px;
    color: white;
    text-align: center;
    cursor: pointer;
    width: 40%;
}

.newButtonInves {
    background-color: #1a2f67;
    margin-top: 10px;
    color: white;
    padding: 10px 20px;
}


.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-inline: 40px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
}

.buttonToggle1 {
    background-color: #004f9f;
    font-size: 1.2rem;
    color: white;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    right: 10px;
    top: 60px;

}

@media screen and (min--moz-device-pixel-ratio: 0.9),
screen and (-o-min-device-pixel-ratio: 0.9/1),
screen and (-webkit-min-device-pixel-ratio: 0.9),
screen and (max-resolution: 119dpi) {
    .docBody {
        font-size: 20px;
    }
    .invesBody {
        font-size: 20px;
    }
    .inputsRep {
        width: 80%;
    }
    .h1Doc2 {
        font-size: 1.5rem;
    }
   

  /*  .inputsDoc {
        font-size: 18px;
        height: 45px;
        background-color: #ff5c5c;
    }*/

   /* .optionInputsDoc {
        font-size: 18px;
        height: 45px;
    }*/
}