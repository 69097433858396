.emiBody {
    background-color: #f7f7f7;
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}


.mainDoc11 {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 10px 10px;
    align-items: center;
    width: 70%;
    margin-left: 25%;
}

.mainDoc2 {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 10px 10px;
    align-items: center;
    border-top-width: 0px;
    padding: 3%;
    background-color: #0d0d0d00;
    margin-left: 22%;
    width: 100%;
}

.mainDoc22 {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 10px 10px;
    align-items: center;
    border-top-width: 0px;
    padding: 3%;
    background-color: #0d0d0d00;
    margin-left: 22%;
    width: 100%;
    margin-left: 0%;
}

.mainDoc3 {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 10px 10px;
    align-items: center;
    border-top: 5px #1a2f67 solid;
    min-width: 100vh;
    min-height: 45vh;
    margin: 3%;
    padding: 3%;
    background-color: #fefeff;
    margin-left: 5%;
}

.buttonToggle {
    background-color: #1a2f67;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
    padding-inline: 10%;
    font-size: 18px;
    font-weight: 600;
    margin: 15px;
    transition: background-color 0.3s ease;
    box-shadow: #000 0px 1px 2px;

}

.buttonEmissor {
    display: flex;
    justify-content: start;
    cursor: pointer;
    width: 100%;
    text-align: end;
    margin-left: 70px;;
}

.mainAvalista {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* padding: 2%;*/
    padding-top: 2%;
    padding-bottom: 2%;
    align-items: end;
    justify-content: center;
   
}
.mainAvalista2 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* padding: 2%;
    padding-top: 2%;*/
    padding-bottom: 2%;
    align-items: end;
    justify-content: space-around;
}


.h1DocAva {
    display: flex;
    font-size: 1.2rem;
    width: 100%;
}
.h1DocAva2 {
    display: flex;
    font-size: 1.3rem;
    width: 100%;
    color: #1a2f67;
    font-weight: 600;
}


.inputsDocAva {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: large;
    padding-left: 1vh;
    padding-right: 1vh;
    height: 5vh;
    border: #cfcfcf 2px solid;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 110%;
    margin-bottom: 2vh;
}

.divInputsDocAva {
    display: flex;
    flex-direction: column;
    width: 42%;
    padding: 12px
  
}
.divInputsDocAva2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: pink;
}

.formDoc1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-left: 5px #1a2f67 solid;
    background-color: #fefeff;
    border-radius: 10px 10px 10px 10px;
    min-width: 130vh;
    padding: 2%;
}
.formDoc12 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /*border-left: 5px #1a2f67 solid;*/
    /*margin: 3%;*/
    padding: 3%;
    background-color: #fefeff;
    border-radius: 10px 10px 10px 10px;
    min-width: 130vh;
}
.formDoc13 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-left: 5px #1a2f67 solid;
    margin: 3%;
    padding-left: 2%;
    background-color: #fefeff;
    border-radius: 10px 10px 10px 10px;
    min-width: 130vh;
}
.formDocProp{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-left: 5px #1a2f67 solid;
    margin: 3%;
    padding: 3%;
    background-color: #fefeff;
    border-radius: 10px 10px 10px 10px;
}

.formDoc2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 3%;
    padding: 3%;
    background-color: #fefeff;
    border-radius: 10px 10px 10px 10px;
}



.divTitleDoc {
    display: flex;
    width: 100%;
}

.divTitleDoc33 {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
}

.h1Doc {
    color: #1a2f67;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: start;
    margin-bottom: 3vh;
    margin-top: 3vh;
}

.divInputsDoc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    padding: 12px;
   /* border: 2px solid yellow;*/
  
    
}

.divInputsDoc1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 25px;
}

.divInputsDoc2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.divInputsDoc3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-content: space-between;
    justify-content: center;
}

.divInputsDoc33 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-content: space-between;
    justify-content: center;
}


/*.labelInputsDoc {
    font-weight: 600;
    text-align: left;
    width: 75%;

}*/

/*.labelInputsDoc1 {
    font-weight: 600;
    text-align: left;
}*/

/*.inputsDoc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-left: 2vh;
    padding-right: 2vh;
    height: 5vh;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 85%;
    margin-bottom: 2vh;
    background-color: #0d0d0d00;
}*/

.inputsDoc1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-left: 2vh;
    padding-right: 2vh;
    height: 5vh;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 80%;
    margin-bottom: 2vh;
}

.inputsDoc2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-left: 2vh;
    padding-right: 2vh;
    height: 5vh;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 70%;
    margin-bottom: 2vh;
}

.inputsDoc3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-left: 2vh;
    padding-right: 2vh;
    height: 5vh;
    border: #cfcfcf 2px solid;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 30%;
    margin-bottom: 2vh;
}

/*.selectDoc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-left: 3%;
    padding-right: 3%;
    height: 6vh;
    width: 77%;
}*/

.expandedDoc {
  /*  max-width: 1000px;*/
    /* Defina o valor máximo apropriado para a altura expandida */
    transition: max-width 0.7s ease-in-out;
    opacity: 1;
}

/* Classe para ocultar os elementos */
.collapsed {
    max-width: 200px;
    transition: max-width 0.7s ease-in-out, opacity 0.5s ease-in-out;
}

.collapsed1 {
    max-width: 95px;
    opacity: 0;
    transition: max-width 0.7s ease-in-out, opacity 0.5s ease-in-out;
}

.divFull {
    width: 100%;

}


.loading {
    width: 100%;
    text-align: center;
    color: #1a2f67;
    font-size: 20px;
}

.addButton {
    color: #1a2f67;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 1vh;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 15px;
    width: 100%;
    text-align: center;
}

.addButton2 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #1a2f67;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 2vh;
    width: 100%;
   /* margin-left: 2%;*/
    height: 5vh;
}
.addButton3 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #1a2f67;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 2vh;
    width: 93%;
   /* margin-left: 2%;*/
    height: 5vh;
}
.addButton4 {
    color: #1a2f67;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 1vh;
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.div12 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

}

.addButton1 {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 1vh;
    font-size: 18px;
    margin: 15px;
    width: 30vh;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #1a2f67;
    color: white;
}

.file-input {
    border: 2px solid #1a2f67;
    /* Use your primary color for consistency */
    background-color: #fff;
    color: #1a2f67;
    padding: 10px;
    border-radius: 5px;
    transition: border 0.3s;
    transition: 360ms;
    margin-bottom: 15px;
    width: 60%;
}

.file-input:hover {
    border: 2px solid #007bff;
    padding: 12px;
    cursor: pointer;
    /* Mudar a cor da borda ao passar o mouse */
}

/* Estilização adicional conforme necessário */
.rowDiv {
    display: flex;
    flex-wrap: wrap;
    /*background-color: #233c80;*/
}

.button2 {
    background-color: #1a2f67;
    padding: 10px;
    border-radius: 8px;
    color: white;
    text-align: center;
    cursor: pointer;
}

.button2Div {
    display: flex;
    justify-content: center;
    width: 100%;
}

.newButtonEm {
    padding: 0px;
    border-radius: 8px;
    color: #233c80;
    text-align: start;
    cursor: pointer;
    width: 100%;
    font-size: 1.3rem;
    font-weight: 650;
    margin-top: 2%;
}

.emissorDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

}



.checkboxLabel {
    font-size: 14px;
    font-weight: 500;
    color: #1a2f67;
}

.checkboxDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #e8e8e8;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 1vh;
}

.checkboxInput {
    margin-left: 10px;
    margin-right: 10px;

    font-size: 14px;
    margin: 15px;
    width: 20vh;
    text-align: center;

}

.buttonEmissor1 {
    display: flex;
    width: 100%;
    color: white;
    padding-top: 50px;
    /*justify-content: end;*/
}

.emissorSend {
    background-color: #004f9f;
    padding: 1% 5%;
    cursor: pointer;
    border-radius: 8px;
}

.emissorBack {
    border: 2px solid #1a2f67;
    color: #1a2f67;
    margin-inline: 10px;
    padding: 1% 4%;
    border-radius: 8px;
    cursor: pointer;
}

@media screen and (min--moz-device-pixel-ratio: 0.9),
screen and (-o-min-device-pixel-ratio: 0.9/1),
screen and (-webkit-min-device-pixel-ratio: 0.9),
screen and (max-resolution: 119dpi) {
    .emiBody {
        font-size: 20px;
    }
    .addButton3 {
       font-size: 20px;
    }
    .newButtonEm {
        font-size: 1.3rem;
    }
    .h1DocAva2 {
        font-size: 1.5rem;
       
    }
    

   /* .inputsDoc {
        font-size: 18px;
        height: 45px;
    }*/

   /* .optionInputsDoc {
        font-size: 18px;
        height: 45px;
    }*/
}