/* Estilos para os campos de upload */
.customFileInput,
.documentField label {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #1B2E67;
    border: 1px solid #013872;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    padding: 10px 20px;
    margin: 10px;
    text-align: center;
    min-width: 35%;
    height: 5vh;
    width: 40%;
}
.customFileInput2,
.documentField2 label {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #1B2E67;
    border: #cfcfcf 2px solid;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    text-align: center;
    height: 46px;
    font-size: 18px;
  /*  width: 100%;*/
    margin-top: 20px;
    padding-left: 10px;
    justify-content: center;
   
}
.customFileInput3,
.documentField2 label {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #1B2E67;
    border: #cfcfcf 2px solid;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    text-align: center;
    height: 46px;
    font-size: 18px;
    width: 80%;
    margin-top: 20px;
    padding-left: 10px;
}
.customFileInput4,
.documentField2 label {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #1B2E67;
    border: #cfcfcf 2px solid;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    text-align: center;
    height: 46px;
    font-size: 18px;
    width: 80%;
    margin-top: 20px;
    padding-left: 10px;
   
}
.customFileInput:hover,
.documentField label:hover {
    background-color: #efefef;
}

.uploadIcon {
    font-size: 20px;
    margin-right: 16px;
}

.uploadText {
    display: flex;
    flex-direction: column;
    /*align-items: flex-start;*/
}

/* Estilos para os campos de informações de arquivo */
.fileInfo {
    margin-top: 10px;
}

.fileInfo p {
    margin: 5px 0;
    font-size: 14px;
}

/* Estilos para as mensagens de sucesso e erro */
.successMessage,
.errorMessage {
    font-weight: bold;
    margin-top: 10px;
}

.successMessage {
    color: green;
}

.errorMessage {
    color: red;
}

/* CSS para o componente PostNote */
.uploadForm {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    margin: 20px;
    /*min-height: 40vh;*/
}

/* Estilos para o botão Adicionar Documento dos Sócios */
.addDocumentButton {
    background-color: #92c700;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 20px;
    margin: 10px;
    text-align: center;
    transition: background-color 0.3s ease;
    font-weight: bold;
    width: 40%;
}

.addDocumentButton:hover {
    background-color: #013872;
}

.customFileInput.success {
    background-color: green;
    /* Altere a cor para verde quando o upload for bem-sucedido */
}

/* Estilos para o botão de envio da solicitação */
.submitDisabled {
    width: 100%;
    background-color: gray;
    /* Cor cinza quando desabilitado */
}

.submitEnabled {
    width: 100%;
    background-color: #fff;
    /* Cor azul quando habilitado */
    color: white;
    cursor: pointer;
}

.submitEnabled:hover {
    background-color: #003a73;
    /* Cor azul escuro quando hover */
}

.customFileInput.success {
    background-color: green;
    /* Altere a cor para verde quando o upload for bem-sucedido */
}

.uploadedFilesList {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.uploadedFileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    padding: 2px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 6vh;
}

.uploadedFileItem button {
    background-color: #ff3333;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 5px;
    font-size: 12px;
    margin-bottom: 15px;
}

.uploadedFileItem button:hover {
    background-color: #ff0000;
}

.emissorSelector {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: white;
    transition: background-color 0.3s ease;
    font-weight: bold;
    margin: 10px;
    text-align: start;
    width: 73%;
}

.emissorSelector:hover {}

.emissorSelectorIcon {
    font-size: 20px;
    margin-right: 16px;
}

.emissorSelectorText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.span1 {
    color: #003a73;
    font-size: 18px;
}

.select1 {
    margin-top: 10px;
    width: 60%;
    cursor: pointer;
    height: 7vh;
    border-radius: 5px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}

.select1 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right center;
    cursor: pointer;
    padding-right: 20px;
}

.select1 option {
    cursor: pointer;

}

.select1,
.select1 option {
    text-align: center;
    cursor: pointer;

}

.select1:focus {
    border: 1px solid #007bff;
    outline: none;
}

.select1:active {
    border: 1px solid #007bff;
}
@media (min-width: 1800px) {
    .documentField3 label {
        height: 46px;
        width: 70%;
        margin-top: 20px;
        font-size: 20px;
        border: 2px solid red !important
    }
}
@media screen and (min--moz-device-pixel-ratio: 0.9),
screen and (-o-min-device-pixel-ratio: 0.9/1),
screen and (-webkit-min-device-pixel-ratio: 0.9),
screen and (max-resolution: 119dpi) {
    .customFileInput2,
    .documentField2 label {
        height: 46px;
       /* width: 83%;*/
        margin-top: 20px;
        font-size: 20px;
    }
    .documentField3 label {
        height: 46px;
        width: 70%;
        margin-top: 20px;
        font-size: 20px;
    }
}